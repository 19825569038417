<template>
    <div class="ce-content">

        <div class="row ce-row">

            <!-- First col ------------------------>
            <div class="col-md-12">

                <!-- ce-content-inner ------------------------------>
                <div class="ce-content-inner ce-user-list-wrap">
                    <div class="row user-list-header">
                        <div class="col-md-12">
                            <div class="">
                                <h3>User List</h3>
                                 <div class="ce-create-role-btn-wrap">
                                    <button class="createuserbtn" data-toggle="modal" data-target="#ce-create-user">
                                        Create new user
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 user-list-search">
                            <i class="fas fa-search"></i>
                            <input class="form-control" type="search" autocomplete="new-password" placeholder="Search user" v-model="textParam" v-on:keyup="getUsers()">
                        </div>
                    </div>

                    <div class="col-md-12">
                        <!-- Create user Modal -->
                        <div class="modal fade ce-create-user" id="ce-create-user" tabindex="-1" role="dialog" aria-labelledby="ce-create-user-modal" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 v-if="isEdit" class="modal-title" id="ce-role-edit-modal">Edit user</h5>
                                        <h5 v-else class="modal-title" id="ce-role-create-modal">Create new user</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancelData">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="row">
                                            <div class="col-sm-3">
                                                Name
                                            </div>
                                            <div class="col-sm-9">
                                                <div class="form-group">
                                                    <input type="text" ref="name" name="name"
                                                            v-model="new_user.name"
                                                            placeholder="Enter user name!" class="form-control"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-3">
                                                Email
                                            </div>
                                            <div class="col-sm-9">
                                                <div class="form-group">
                                                    <input type="text" ref="Email" name="Email"
                                                            v-model="new_user.email"
                                                            placeholder="Enter user Email!"
                                                            class="form-control"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-3">
                                                Password
                                            </div>
                                            <div class="col-sm-9">
                                                <div class="form-group">
                                                    <div v-if="isEdit">
                                                        <input type="password" ref="Password" name="Password" v-model="new_user.newpassword" placeholder="Change password" class="form-control"/>
                                                    </div>
                                                    <div v-else>
                                                        <input type="password" ref="Password" name="Password" v-model="new_user.password" placeholder="Create user password!" class="form-control"/><br>
                                                        <input type="password" ref="Password" name="Password" v-model="new_user.password_confirmation" placeholder="Confirm password!" class="form-control"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-3">
                                                Phone
                                            </div>
                                            <div class="col-sm-9">
                                                <div class="form-group">
                                                    <input type="text" ref="Phone" name="Phone" v-model="new_user.phone" placeholder="Enter user phone number!" class="form-control"/>
                                                </div>
                                            </div>
                                        </div>
                                        <!--<div v-if="!isEdit">
                                            <div class="row">
                                                <div class="col-sm-3">
                                                    Assign Role
                                                </div>
                                                <div class="col-sm-9">
                                                    <div class="form-group">
                                                        <div id="select" v-for="role in rolesList" :key="role.role_id">
                                                            <input type="radio" :value="role.role_id" v-model="selectedRole">
                                                            <label>{{role.role_name}}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>-->
                                      <div v-if="!isEdit" class="cnu-ag">
                                        <div class="row">
                                          <div class="col-sm-3">
                                            Assign Group
                                          </div>
                                          <div class="col-sm-9">
                                            <div class="form-group">
                                              <div id="select" v-for="group in groupList" :key="group.id">
                                                <input type="radio" :value="group.id" v-model="selectedGroup" id="cnu-ag-input1">
                                                <label for="cnu-ag-input1">{{group.name}}</label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="modal-footer">
                                        <button v-on:click="cancelData" type="button" class="btn btn-danger"
                                                data-dismiss="modal">Cancel
                                        </button>
                                        <button v-if="isEdit" v-on:click="updateUser" type="button" data-dismiss="modal"
                                                class="btn btn-primary">Update
                                        </button>
                                        <button v-else v-on:click="createUser" type="button" data-dismiss="modal"
                                                class="btn btn-primary">Create
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                      <!-- Create Modal For Showing Scopes Start-->

                      <div class="modal fade ce-assign-role" id="show-scopes" tabindex="-1" role="dialog"
                           aria-labelledby="ce-assign-role-modal" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title">View All Scopes</h5>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <div class="ce-content-inner">
                                <li class="" v-for="scope in this.clientScopesForUser" :key="scope.id">{{scope.name}} ({{ scope.sso_clients !==null ? scope.sso_clients.name : 'SSO' }})</li>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button v-on:click="cancelData" type="button" class="btn btn-danger"
                                      data-dismiss="modal">Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Create Modal For Showing Scopes End -->

                        <!-- Assign client scopes Modal start-->

                      <div class="modal fade ce-assign-role" id="ce-assign-scopes" tabindex="-1" role="dialog"
                           aria-labelledby="ce-assign-role-modal" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="ce-role-assign-modal">Assign Scopes</h5>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancelData">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <!--<div class="row">
                                <div class="col-sm-3">
                                  ID
                                </div>
                                <div class="col-sm-9">
                                  <div class="form-group">
                                    {{selectedUser.id}}
                                  </div>
                                </div>
                              </div>-->
                              <div class="row">
                                <div class="col-sm-3">
                                  Name
                                </div>
                                <div class="col-sm-9">
                                  <div class="form-group">
                                    {{bulkAssignData.user}}
                                  </div>
                                </div>
                              </div>
                              <div class="row multiselect-scopes">
                                <div class="col-sm-3">
                                  Scopes
                                </div>
                                <div class="col-sm-9">
                                  <div class="form-group">
                                    <Multiselect
                                        v-model="selectedScopes"
                                        :options="clientScopes"
                                        :showLabels="false"
                                        label="name"
                                        :searchable="true"
                                        placeholder="Select Scopes"
                                        :multiple="true"
                                        :close-on-select="false"
                                        :clear-on-select="false"
                                        track-by="id"
                                    >
                                      <template slot="selection" slot-scope="{ values, isOpen }">
                                                                <span
                                                                    class="multiselect__single"
                                                                    v-if="values.length &amp;&amp; !isOpen"
                                                                >{{ values.length }} options selected</span
                                                                >
                                      </template>
                                    </Multiselect>
                                    <!--<div class="checkbox" v-for="client in clientScopes" :key="client.id">
                                      <input type="checkbox" :value="client.id" v-model="selectedScopes">
                                      <label>{{client.name}} {{client.sso_clients ? '('+client.sso_clients.name+')' : ''}} </label>
                                    </div>-->
                                 </div>
                             </div>
                         </div>
                          <!--<div class="row assign-scopes-checklist">
                            <div class="col-12">
                              <div class="form-group">
                                <input
                                    id="assign-scopes-checklist"
                                    type="checkbox"
                                    v-model="isSync"
                                    true-value="1"
                                    false-value="0"
                                    placeholder=""
                                >
                                <label for="assign-scopes-checklist">Remove all other permissions and client access.</label>
                              </div>
                            </div>
                          </div>-->
                     </div>
                     <div class="modal-footer">
                         <button type="button" class="btn btn-danger"
                                 data-dismiss="modal" v-on:click="cancelData">Cancel
                         </button>
                         <button type="button" data-dismiss="modal"
                                 class="btn btn-primary" v-on:click="saveBulkAssign">Update
                         </button>
                     </div>
                 </div>
             </div>
         </div>

       <!-- Assign client scopes Modal end -->

                        <!-- Create user Modal End -->
                        <!-- Assign role Modal -->
                        <!--<div class="modal fade ce-assign-role" id="ce-assign-role" tabindex="-1" role="dialog"
                                aria-labelledby="ce-assign-role-modal" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="ce-role-assign-modal">Assign Role</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancelData">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="row">
                                            <div class="col-sm-3">
                                                ID
                                            </div>
                                            <div class="col-sm-9">
                                                <div class="form-group">
                                                    {{selectedUser.id}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-3">
                                                Name
                                            </div>
                                            <div class="col-sm-9">
                                                <div class="form-group">
                                                    {{selectedUser.name}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-3">
                                                Roles
                                            </div>
                                            <div class="col-sm-9">
                                                <div class="form-group">-->
                                                    <!-- <div id="select" v-for="role in rolesList" :key="role.role_id">
                                                        <input type="radio" :value="role.role_id" v-model="selectedRole">
                                                        <label>{{role.role_name}}</label>
                                                    </div> -->
                                                   <!-- <div class="checkbox" v-for="role in rolesList" :key="role.role_id">
                                                        <input type="checkbox" :value="role.role_id" v-model="roleArray" v-on:change="multipleCheck">
                                                        <label>{{role.role_name}}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-danger"
                                                data-dismiss="modal" v-on:click="cancelData">Cancel
                                        </button>
                                        <button type="button" data-dismiss="modal"
                                                class="btn btn-primary" v-on:click="updateRoleAssign">Update
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>-->
                        <!-- Assign role Modal End -->
                        <!-- Assign client Modal -->
                        <div class="modal fade ce-assign-client" id="ce-assign-client" tabindex="-1" role="dialog"
                                aria-labelledby="ce-assign-client-modal" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="ce-client-assign-modal">Assign Client</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancelData">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="row">
                                            <div class="col-sm-3">
                                                ID
                                            </div>
                                            <div class="col-sm-9">
                                                <div class="form-group">
                                                    {{selectedUser.id}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-3">
                                                Name
                                            </div>
                                            <div class="col-sm-9">
                                                <div class="form-group">
                                                    {{selectedUser.name}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row ul-ac-clients">
                                            <div class="col-sm-3">
                                                Clients
                                            </div>
                                            <div class="col-sm-9">
                                                <div class="form-group">
                                                    <div class="checkbox" v-for="client in clientsList" :key="client.id">
                                                        <input type="checkbox" :value="client.id" v-model="selectedClients">
                                                        <label>{{client.name}}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-danger"
                                                data-dismiss="modal" v-on:click="cancelData">Cancel
                                        </button>
                                        <button type="button" data-dismiss="modal"
                                                class="btn btn-primary" v-on:click="updateClientAssign">Update
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Assign Client Modal End -->
                    </div>


                  <!-- Assign group Modal -->
                  <div class="modal fade ce-assign-client" id="ce-assign-group" tabindex="-1" role="dialog"
                       aria-labelledby="ce-assign-client-modal" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="ce-group-assign-modal">Assign Group</h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancelData">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <div class="row">
                            <div class="col-sm-3">
                              ID
                            </div>
                            <div class="col-sm-9">
                              <div class="form-group">
                                {{selectedUser.id}}
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-3">
                              Name
                            </div>
                            <div class="col-sm-9">
                              <div class="form-group">
                                {{selectedUser.name}}
                              </div>
                            </div>
                          </div>
                          <div class="row ul-ac-clients">
                            <div class="col-sm-3">
                              Groups
                            </div>
                            <div class="col-sm-9">
                              <div class="form-group">
                                <div class="checkbox" v-for="group in groupList" :key="group.id">
                                  <input type="checkbox" :value="group.id" v-model="selectedGroup">
                                  <label>{{group.name}}</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-danger"
                                  data-dismiss="modal" v-on:click="cancelData">Cancel
                          </button>
                          <button type="button" data-dismiss="modal"
                                  class="btn btn-primary" v-on:click="updateGroupAssigned">Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Assign Groups End -->



                    <div class="row user-list-body">
                        <div class="col-12">
                            <div class="row new-list-table user-list-table">
                                <div class="col-12">
                                    <div class="list-table-header">
                                        <div class="list-header id">SN</div>
                                        <div class="list-header name">Personal Details</div>
                                        <div class="list-header email">Groups</div>
                                        <div class="list-header phone">Roles</div>
                                        <div class="list-header phone">Scopes</div>
                                        <div class="list-header phone">App</div>
                                        <div class="list-header actions">Actions</div>
                                    </div>

                                    <div class="list-table-body">
                                        <div v-for="(user,index) in usersList" :key="user.id">
                                            <div class="list-row">
                                              <div class="list-body id">{{index+1}}</div>
                                              <div class="list-body name">{{user.name}} ({{user.id}})<br>{{user.email}}<br>{{user.phone}}</div>
                                              <div class="list-body email"><div><li v-for="group in user.groups" :key="group.id">{{group.name}}</li></div></div>
                                              <div class="list-body email"><div><li v-for="da in getRolesFromGroups(user.groups)" :key="da">{{da}}</li></div></div>
                                                <div class="list-body phone"><div v-if="user.client_scopes.length <= 2"><li v-for="scope in user.client_scopes" :key="scope.id">{{scope.name}} {{ scope.sso_clients ? '('+scope.sso_clients.name+')' : ''}}</li></div><div v-else><a href data-toggle="modal" data-target="#show-scopes" @click="loadScopes(user)">View All</a></div></div>
                                              <div class="list-body email"><div><li v-for="client in user.sso_clients" :key="client.id">{{client.name}}</li></div></div>
                                                <div class="list-body actions">
                                                    <!--<div class="assignRole">
                                                        <span name="Assign Role" @click="updateOverall(user)" title="VIEW/ASSIGN ROLE" data-toggle="modal" data-target="#ce-assign-role">
                                                            <i class="cts-configure cts_recipient_icon"></i>
                                                        </span>
                                                    </div>-->
                                                  <div class="assignRole">
                                                          <span name="Assign Role" @click="bulkAssign(user)" title="ASSIGN CLIENT SCOPES" data-toggle="modal" data-target="#ce-assign-scopes">
                                                              <i class="cts-configure cts_recipient_icon"></i>
                                                          </span>
                                                  </div>
                                                   <!--<div class="assignClient">
                                                        <span name="Assign Client" @click="updateOverall(user)" title="VIEW/ASSIGN CLIENTS" data-toggle="modal" data-target="#ce-assign-client">
                                                            <i class="cts-active-user cts_recipient_icon"></i>
                                                        </span>
                                                    </div> -->
                                                  <div class="assignClient">
                                                        <span name="Assign Group" @click="updateGroup(user)" title="VIEW/ASSIGN GROUPS" data-toggle="modal" data-target="#ce-assign-group">
                                                            <i class="cts-group cts_recipient_icon"></i>
                                                        </span>
                                                  </div>
                                                    <div class="updateUser">
                                                        <span name="Update User" @click="userUpdateModal(user.id)" title="UPDATE USER" data-toggle="modal" data-target="#ce-create-user">
                                                            <i class="cts-settings cts_recipient_icon"></i>
                                                        </span>
                                                    </div>
                                                  <div class="revokeUserAccess">
                                                        <span name="Revoke User Access" @click="revokeUserAccess(user.id)" title="REVOKE USER ACCESS">
                                                            <i class="cts-active-user cts_recipient_icon"></i>
                                                        </span>
                                                  </div>
                                                    <div class="deleteUser">
                                                        <span name="Delete User" @click="deleteUser(user.id)" title="DELETE USER">
                                                            <i class="cts-delete cts_recipient_icon"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <!-- ce-content-inner End ------------------------>

            </div>
            <!-- First col End -------------------------------->

        </div>
    </div>
</template>

<style>
    .ce-main-wrapper .ce-wrapper .ce-content .ce-content-inner .ce-create-role-btn-wrap .ce-btn-create-role-submit {
        width: 180px;
        height: 45px;
        background: #00ccff;
        color: #fff;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        margin-bottom: 20px;
    }
    .cts_recipient_icon{
        color:#2cc4ef;
        font-size:18px;
        cursor: pointer;
        margin: 7px;
    }
    .createuserbtn{
        font-size: 12px;
        padding: 5px 9px;
        font-weight: 700;
        height: unset;
        width: unset;
        border-radius: 4px;
        background: #0cf;
        color: #fff;
        text-transform: uppercase;
        cursor: pointer;
        margin-bottom: 20px;
        margin-left: 35px;
        border: 0px;
        border-color: white;
    }
    .ce-create-role-btn-wrap{
        position: absolute;
        top: -3px;
        left: 90px;
    }
    .assign-scopes-checklist .form-group {
        margin-top: 10px;
        opacity: 0.9;
    }
    .multiselect-scopes .form-group {
        opacity: 1!important;
    }
    .multiselect-scopes .form-group input {
        border: none;
    }
    .assign-scopes-checklist .form-group label {
        margin-left: 10px;
        line-height: 1.5;
        vertical-align: text-bottom;
    }
    #ce-assign-scopes .col-sm-9 .form-group {
        font-weight: bold;
        opacity: 0.9;
    }
    #ce-assign-client .modal-body .col-sm-9 .form-group {
        font-weight: bold;
        opacity: 0.9;
    }
    #ce-assign-client .modal-body .ul-ac-clients .col-sm-9 .checkbox {
        margin-bottom: 6px;
    }
    #ce-assign-client .modal-body .ul-ac-clients {
        margin-top: 10px;
    }
    #ce-assign-client .modal-body .ul-ac-clients .col-sm-9 .checkbox label {
        font-weight: 400;
        margin-left: 5px;
    }
    @media (max-width: 575px) {
        .ce-user-list-wrap .user-list-search {
            position: relative;
            right: 0px;
            top: 15px;
        }
    }
    #ce-create-user .modal-body .cnu-ag .form-group label {
        margin-left: 5px;
    }
    .cts-settings.cts_recipient_icon {
        font-weight: bold;
    }
    .multiselect__tags .multiselect__single, .send_form .input_box input, .multiselect__tags .multiselect__placeholder {
        font-size: 14px !important;
        color: #656568 !important;
    }
    .multiselect__tags {
        border: 1px solid #bfbfbf !important;
        padding-left: 15px;
    }
    .multiselect__tags .multiselect__single {
        overflow: hidden;
    }
    .multiselect .multiselect__tags {
        height: 40px;
    }
    .multiselect__option--highlight {
        background: #00ccff!important;
    }
    .ce-content-inner.ce-user-list-wrap .new-list-table .col-12 {
        min-width: 1000px;
    } 
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>

import Api from '../includes/Api';
import Multiselect from "vue-multiselect";

export default {
    name:'UserList',
    components: {
      Multiselect
    },
    data() {
        return {
            new_user: {
                id: '',
                name: '',
                email: '',
                password: '',
                password_confirmation:'',
                newpassword: '',
                phone: ''
            },
            isEdit: false,
            usersList: [],
            rolesList: [],
            clientsList: [],
            selectedUser: {
                name: '',
                id: '' 
            },
            selectedRole: null,
            roleArray: [], 
            selectedClients: [],
            textParam: '',
            selectedScopes: [],
            bulkAssignData: {
              id:null,
              user:null,
              client_scopes:[],
              is_sync:0
            },
            clientScopes:[],
            groupList:[],
            selectedGroup:[],
            isSync:0,
            clientScopesForUser : null
        }
    },
    methods: {
        getGroups() {
          Api.get('/list-group').then((response) => {
            this.groupList = response.data.data;
          });
        },
        getUsers() {
            Api.get('/list-user?textParam=' + this.textParam).then((response) => {
                this.usersList = response.data.users;
            });
        },
        getRoles() {
            Api.get('/role/list').then((response) => {
                this.rolesList = response.data.roles;
            });
        },
        getClients() {
            Api.get('/list-access-client').then((response) => {
                this.clientsList = response.data.data;
            });
        },
        updateOverall(user) {
            this.selectedUser.name = user.name;
            this.selectedUser.id = user.id;
            Api.get('/view-assigned-role/' + this.selectedUser.id).then((response) => {
                if (response.data.data != null){
                    this.selectedRole = response.data.data.role_id;
                    this.roleArray.push(this.selectedRole);
                }
                return Api.get('/get-assigned-clients/' + this.selectedUser.id);
            })
            .then((resp) => {
                if (resp.data.data != null){
                    resp.data.data.forEach(item => {
                        this.selectedClients.push(item.id);
                    });
                }
            });
        },
        updateRoleAssign(){
            this.selectedRole = this.roleArray[0];
            let postData = {
                "roleId": this.selectedRole,
                "userId": this.selectedUser.id
            };
            Api.post('/role/assign', postData).then((resp) => {
                if (resp.data.status === 'success') {
                    Api.alert('success', resp.data.message);
                }
            });
            this.cancelData();
        },
        updateClientAssign(){
          if (this.selectedUser.id === '' || this.selectedUser.id === null || this.selectedUser.id === undefined){
            Api.alert("warning", "User ID cannot be empty.");
          }
          else if ( this.selectedClients.length <= 0){
            Api.alert("warning", "Client List cannot be empty.");
          } else {
            let postData = {
                "userId": this.selectedUser.id,
                "clients": this.selectedClients
            };
            Api.post('/assign-client', postData).then((resp) => {
                if (resp.data.status === 'success') {
                    this.cancelData();
                    this.getUsers();
                    Api.alert('success', resp.data.message);
                }
            });
          }
        },
        multipleCheck(){
            if (this.roleArray.length > 1){
                this.roleArray.splice(0,1);
            }
        },
        createUser() {
            if (this.new_user.name == ''){
                Api.alert("warning", "Name cannot be empty.");
            }
            else if (this.new_user.email == ''){
                Api.alert("warning", "Email cannot be empty.");
            }
            else if (this.new_user.password == ''){
                Api.alert("warning", "Password cannot be empty.");
            }
            else if (this.new_user.phone == ''){
                Api.alert("warning", "Phone number cannot be empty.");
            }
            else{
                let postData = {
                    "name": this.new_user.name,
                    "email": this.new_user.email,
                    "password": this.new_user.password,
                    "password_confirmation": this.new_user.password_confirmation,
                    "phone": this.new_user.phone,
                    "groups": this.selectedGroup,
                    "is_sync": this.isSync
                }
                //let selectedRole = this.selectedRole;
                Api.post('/signUp', postData).then((resp) => {
                    if (resp.data.status === 'success') {
                        this.cancelData();
                        this.getUsers();
                        Api.alert('success', resp.data.message);
                    }
                    //this.usersList.push(resp.data.data);
                    //let data = {
                    //    "roleId": selectedRole,
                    //    "userId": resp.data.data.id
                    //}
                    //return Api.post('/role/assign', data);
                })
                //.then((response) => {
                //    if (response.data.status === 'success') {
                //        Api.alert('success', response.data.message);
                //    }
                //});
            }
            this.cancelData();
        },
        userUpdateModal(id){
            this.isEdit = true;
            Api.get('/get-user/' + id).then((resp) => {
                this.new_user.id = resp.data.user.id;
                this.new_user.name = resp.data.user.name;
                this.new_user.email = resp.data.user.email;
                this.new_user.newpassword = resp.data.user.password;
                this.new_user.password = resp.data.user.password;
                this.new_user.phone = resp.data.user.phone;
            });
        },
        updateUser() {
            if (this.new_user.name == ''){
                Api.alert("warning", "Name cannot be empty.");
            }
            else if (this.new_user.email == ''){
                Api.alert("warning", "Email cannot be empty.");
            }
            else if (this.new_user.phone == ''){
                Api.alert("warning", "Phone number cannot be empty.");
            }
            else{
                let postData = {
                    "id": this.new_user.id,
                    "name": this.new_user.name,
                    "email": this.new_user.email,
                    "phone": this.new_user.phone
                }
                if (this.new_user.password != this.new_user.newpassword){
                    postData.password = this.new_user.newpassword;
                    postData.passwordChanged = 1;
                }
                else {
                    postData.passwordChanged = 0;
                }
                Api.post('/update-user', postData).then((resp) => {
                    if (resp.data.status === 'success') {
                        Api.alert('success', resp.data.message);
                    }
                    this.getUsers();
                });
            }
            this.getUsers();
            this.cancelData();
        },
        revokeUserAccess(id){
          this.$confirm(
              {
                message: 'Are you sure you want to revoke access for this user?',
                button: {
                  no: "No",
                  yes: "Yes"
                },
                callback: confirm => {
                  if (confirm){
                    Api.get('/revoke-user-access/' + id).then((resp) => {
                      if (resp.data.status === 'success') {
                        Api.alert('success', resp.data.message);
                      }
                      this.getUsers();
                    });
                  }
                }
              }
          )
        },
        deleteUser(id) {
            this.$confirm(
                {
                    message: 'Are you sure you want to delete this user?',
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    callback: confirm => {
                        if (confirm){
                            Api.get('/delete-user/' + id).then((resp) => {
                                if (resp.data.status === 'success') {
                                    Api.alert('success', resp.data.message);
                                }
                                this.getUsers();
                            });
                        }
                    }
                }
            )
        },
        cancelData() {
            this.selectedUser = {
                id: '',
                name: ''
            };
            this.new_user = {
                id: '',
                name: '',
                email: '',
                phone: '',
                password: '',
                password_confirmation: '',
                newpassword: ''
            };
            this.selectedRole = null;
            this.selectedClients = [];
            this.roleArray = [];
            this.isEdit = false;
            this.selectedUsers = [];
            this.bulkAssignData.id=null;
            this.bulkAssignData.user=null;
            this.bulkAssignData.client_scopes=[];
            this.bulkAssignData.is_sync=0;
            this.selectedGroup=[];
            this.isSync=0;
            this.clientScopesForUser = null;
            this.selectedScopes = [];
        },
      getClientScopes(){
        Api.get('/list-client-scope').then((resp) => {
          if (resp.data.status === 'success') {
            this.clientScopes = resp.data.data;
          }
        });
      },
      bulkAssign(user){
        this.bulkAssignData.id=user.id;
        this.bulkAssignData.user=user.name;
      },
      saveBulkAssign(){
        this.selectedScopes.forEach(item=>
            this.bulkAssignData.client_scopes.push(item.id)
        );
        this.$confirm(
            {
              message: 'Are you sure you want to save all Scopes?',
              button: {
                no: "No",
                yes: "Yes"
              },
              callback: confirm => {
                if (confirm){

                  let root = this;
                  root.bulkAssignData.is_sync = this.isSync;
                  if ( root.bulkAssignData.user === '' || root.bulkAssignData.user === undefined || root.bulkAssignData.user === null ){
                    Api.alert("warning", "Name cannot be empty.");
                  } else if ( root.bulkAssignData.client_scopes.length <= 0 ) {
                    Api.alert("warning", "Please select Users.");
                  } else {
                    Api.post('/bulk-add-scopes-to-user', root.bulkAssignData).then((response) => {
                      if (response.data.status === 'success') {
                        this.cancelData();
                        this.getUsers();
                        Api.alert('success', response.data.message);
                      }
                    });
                  }

                }
              }
            }
        )
      },
      updateGroup(user){
        this.selectedUser.id=user.id;
        this.selectedUser.name=user.name;
        let groups = user.groups;
        groups.forEach(group=>{
          this.selectedGroup.push(group.id);
        });
      },
      updateGroupAssigned(){

        this.$confirm(
            {
              message: 'Are you sure you want to save Groups?',
              button: {
                no: "No",
                yes: "Yes"
              },
              callback: confirm => {
                if (confirm){

                  let postData = {
                    id : this.selectedUser.id,
                    groups : this.selectedGroup
                  }

                  if ( postData.id === '' || postData.id === undefined || postData.id === null ){
                    Api.alert("warning", "User Id cannot be empty.");
                  } else if ( postData.groups.length <= 0 ) {
                    Api.alert("warning", "Please select Group.");
                  } else {
                    Api.post('/bulk-add-groups-to-user', postData).then((response) => {
                      if (response.data.status === 'success') {
                        this.cancelData();
                        this.getUsers();
                        Api.alert('success', response.data.message);
                      }
                    });
                  }

                }
              }
            }
        )

      },
      getRolesFromGroups(groups){
          let rolesList = [];
          let temp=null;
          groups.forEach(group=>{
              for (let client of group.client_roles){
                temp = client.name + ' ['+ group.name +']',
                rolesList.push(temp)
              }
          });
          return (rolesList.length>0) ? rolesList : null;
      },
      loadScopes(user){
        this.clientScopesForUser = user.client_scopes;
      }
    },
    created() {
        this.getUsers();
        this.getGroups();
        this.getClients();
        this.getClientScopes();
    }
}
</script>